@font-face {
    font-family: "cy-custom-icons";
    src: url("./cy-custom-icons.eot?554def8fe8441c465940a1be1b9172f9?#iefix") format("embedded-opentype"),
url("./cy-custom-icons.woff2?554def8fe8441c465940a1be1b9172f9") format("woff2"),
url("./cy-custom-icons.woff?554def8fe8441c465940a1be1b9172f9") format("woff"),
url("./cy-custom-icons.ttf?554def8fe8441c465940a1be1b9172f9") format("truetype"),
url("./cy-custom-icons.svg?554def8fe8441c465940a1be1b9172f9#cy-custom-icons") format("svg");
}

i.icon.call-queue,
i.icon.cy,
i.icon.dialpad,
i.icon.exact,
i.icon.rex-alt,
i.icon.rex,
i.icon.salesforce-text,
i.icon.salesforce {
    font-family: cy-custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon.call-queue:before {
    content: "\f101";
}
i.icon.cy:before {
    content: "\f102";
}
i.icon.dialpad:before {
    content: "\f103";
}
i.icon.exact:before {
    content: "\f104";
}
i.icon.rex-alt:before {
    content: "\f105";
}
i.icon.rex:before {
    content: "\f106";
}
i.icon.salesforce-text:before {
    content: "\f107";
}
i.icon.salesforce:before {
    content: "\f108";
}
