i.icon.voicemail:after { content: '\F897'; }
i.icon.phone-slash:after { content: '\F3DD'; }
i.icon.chalkboard-teacher:after { content: '\F51C'; }
i.icon.toilet:after { content: '\F7D8'; }
i.icon.door-open:after { content: '\F52B'; }
i.icon.user-slash:after { content: '\F506'; }
i.icon.pen:after { content: '\F304'; }
i.icon.headset:after { content: '\F590'; }
i.icon.phone-alt:after { content: '\F879'; }
i.icon.mobile-alt:after { content: '\F3CD'; }
i.icon.chevron-up:after { content: '\F077'; }
i.icon.chevron-down:after { content: '\F078'; }
i.icon.blender-phone:after { content: '\F6B6'; }
i.icon.file-image:after { content: '\F1C5'; }
